import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading: any = null;

  constructor(public loadingController: LoadingController) {}

  async presentLoading() {
    this.loading = await this.loadingController.create({
      spinner: 'circular',
      message: 'Cargando...',
      translucent: true,
      cssClass: 'loading-class',
      backdropDismiss: false,
    });
    // await this.loading.present();

    return this.loading.present();
  }

  async close() {
    try {
      setTimeout(() => {
        this.loading.dismiss();
      }, 2000);
    } catch (error) {
      console.log(error)
      // this.loading = await this.loadingController.create({
      //   spinner: 'circular',
      //   message: 'Cargando...',
      //   translucent: true,
      //   cssClass: 'loading-class',
      //   backdropDismiss: false,
      //   duration: 1
      // });
      // await this.loading.present();
  
      //  this.loading.present();
      setTimeout(() => {
        this.loading.dismiss();
      }, 2500);

    }
    
  }
}
