import { EventEmitter, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { User } from '../interfaces/User';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  user$= new EventEmitter<User>();
  token:string;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
    this.getUser();
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async get(key: string) {
    return await this._storage?.get(key);
  }

  async getUser() {
    await this._storage?.get('user').then((resp:User) => {
      if(resp!==null){
        this.user$.emit(resp);
        this.token=resp.token;
      }
    });
  }

  async clear() {
    await this._storage.clear();
  }
}
