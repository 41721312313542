import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-client',
  templateUrl: './popover-client.component.html',
  styleUrls: ['./popover-client.component.scss'],
})
export class PopoverClientComponent implements OnInit {

  list=['Todos', 'Fecha', 'Categoria']

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  onClick(num:number){
    this.popoverController.dismiss({item:num})
  }

}
