import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-order',
  templateUrl: './popover-order.component.html',
  styleUrls: ['./popover-order.component.scss'],
})
export class PopoverOrderComponent implements OnInit {

  list=['Todos', 'Fecha', 'Total Orden', 'Status'];

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  onClick(num:number){
    this.popoverController.dismiss({item:num})
  }


}
