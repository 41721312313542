import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-statistics',
  templateUrl: './popover-statistics.component.html',
  styleUrls: ['./popover-statistics.component.scss'],
})
export class PopoverStatisticsComponent implements OnInit {

  list=['Año', 'Mes'];

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  onClick(num:number){
    this.popoverController.dismiss({item:num})
  }

}
