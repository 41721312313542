import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/interfaces/Product';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {

  @Input() product: Product;
  baseurl: string;
  
  constructor() { 
    this.baseurl = environment.url_server;
  }

  ngOnInit() {}

}
