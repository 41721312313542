import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  infoMenu: menu[] = [
    {
      icon: 'grid-outline',
      name: 'Inicio',
      url: '/tabs/home',
    },
    {
      icon: 'cart-outline',
      name: 'Productos',
      url: '/tabs/product',
    },
    {
      icon: 'reader-outline',
      name: 'TAX',
      url: '/tabs/tax',
    },
    {
      icon: 'repeat-outline',
      name: 'Caja Chica',
      url: '/tabs/petty-cash',
    },
    {
      icon: 'bookmarks-outline',
      name: 'Categoria Productos',
      url: '/tabs/category',
    },
    {
      icon: 'calculator-outline',
      name: 'Porcentaje',
      url: '/tabs/earning',
    },
    {
      icon: 'people-outline',
      name: 'Clientes',
      url: '/tabs/client',
    },
    {
      icon: 'receipt-outline',
      name: 'Facturas',
      url: '/tabs/invoices',
    },
    {
      icon: 'calendar-outline',
      name: 'Pedidos',
      url: '/tabs/purchases',
    },
    {
      icon: 'albums-outline',
      name: 'Ordenes',
      url: '/tabs/order',
    },
    {
      icon: 'bookmarks-outline',
      name: 'Categoria Clientes',
      url: '/tabs/customer-category',
    },
    {
      icon: 'newspaper-outline',
      name: 'Notas Clientes',
      url: '/tabs/note',
    },
    {
      icon: 'podium-outline',
      name: 'Estadisticas',
      url: '/tabs/statistics',
    },
    {
      icon: 'storefront-outline',
      name: 'Tiendas',
      url: '/tabs/place',
    },
    {
      icon: 'open-outline',
      name: 'Cerrar sesión',
      url: '/login',
    },
  ];
}

interface menu {
  icon: string;
  name: string;
  url: string;
}
