import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoadingService } from './services/compontents/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private storage: StorageService,
    private loading:LoadingService) {
    // SplashScreen.hide();
    SplashScreen.show({
      showDuration: 5000,
      autoHide: true
    });
  }
}
