import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// components
import { HeaderComponent } from './header/header.component';
import { PopoverInfoComponent } from './popover-info/popover-info.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { ClientItemComponent } from './client-item/client-item.component';
import { PopoverClientComponent } from './popover-client/popover-client.component';
import { PopoverPurchaseComponent } from './popover-purchase/popover-purchase.component';
import { PopoverStatisticsComponent } from './popover-statistics/popover-statistics.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { RouterModule } from '@angular/router';
import { OrderItemComponent } from './order-item/order-item.component';
import { PurchaseItemComponent } from './purchase-item/purchase-item.component';
import { PopoverOrderComponent } from './popover-order/popover-order.component';

const components=[
  HeaderComponent,
  PopoverInfoComponent,
  ProductItemComponent,
  ClientItemComponent,
  PopoverClientComponent,
  MainMenuComponent,
  OrderItemComponent,
  PopoverPurchaseComponent,
  PurchaseItemComponent,
  PopoverStatisticsComponent,
  PopoverOrderComponent,
]

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    IonicModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports:[components]
})
export class ComponentsModule { }
