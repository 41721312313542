import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-popover-purchase',
  templateUrl: './popover-purchase.component.html',
  styleUrls: ['./popover-purchase.component.scss'],
})
export class PopoverPurchaseComponent implements OnInit {

  list=['Todos', 'Fecha', 'Total Pedido', 'Lugar']

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  onClick(num:number){
    this.popoverController.dismiss({item:num})
  }

}
