import { Component, Input, OnInit } from '@angular/core';
import { Purchase } from 'src/app/interfaces/purchase';

@Component({
  selector: 'app-purchase-item',
  templateUrl: './purchase-item.component.html',
  styleUrls: ['./purchase-item.component.scss'],
})
export class PurchaseItemComponent implements OnInit {

  @Input() purchase: Purchase;

  constructor() { }

  ngOnInit() {}

}
