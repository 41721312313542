import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverClientComponent } from '../popover-client/popover-client.component';
import { PopoverInfoComponent } from '../popover-info/popover-info.component';
import { PopoverOrderComponent } from '../popover-order/popover-order.component';
import { PopoverPurchaseComponent } from '../popover-purchase/popover-purchase.component';
import { PopoverStatisticsComponent } from '../popover-statistics/popover-statistics.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() name: string;
  @Input() menu: string;
  @Input() btn: boolean=true;;

  @Output() messageEvent = new EventEmitter<number>();

  constructor(public popoverController: PopoverController) {}

  ngOnInit() {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverInfoComponent,
      event: ev,
      mode: 'ios',
      translucent: false,
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data !== undefined) {
      this.messageEvent.emit(data.item);
    }
  }

  async presentPopoverClients(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverClientComponent,
      event: ev,
      mode: 'ios',
      translucent: false,
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data !== undefined) {
      this.messageEvent.emit(data.item);
    }
  }

  async presentPopoverPurchases(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverPurchaseComponent,
      event: ev,
      mode: 'ios',
      translucent: false,
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data !== undefined) {
      this.messageEvent.emit(data.item);
    }
  }


  async presentPopoverStatistics(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverStatisticsComponent,
      event: ev,
      mode: 'ios',
      translucent: false,
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data !== undefined) {
      this.messageEvent.emit(data.item);
    }
  }

  async presentPopoverOrders(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverOrderComponent,
      event: ev,
      mode: 'ios',
      translucent: false,
    });

    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data !== undefined) {
      this.messageEvent.emit(data.item);
    }
  }
}
